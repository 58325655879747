@tailwind base;

:root {
  --primary: theme("colors.gray.100");
  --primary-hover: theme("colors.gray.200");
  --primary-active: theme("colors.gray.300");
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html {
  font-size: 14px;
  overscroll-behavior: none;
}

body,
html,
#root {
  @apply min-h-screen;
}

@tailwind components;
@tailwind utilities;
